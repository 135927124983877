import React from 'react';
import './Home.css';

function Home() {
    return (
        <div id='home' className='page d-flex flex-start mx-3 mx-lg-auto mt-lg-5 p-lg-5 col-lg-8'>
            <div className="mt-5"> 
                <h2 className="welcome fw-normal">Bienvenue</h2> 
            </div>
        </div>
    )
}

export default Home