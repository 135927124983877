import React from 'react';
import './EnStock.css';

function EnStock() {
    return (
        <div id='enstock' className='page d-flex flex-column align-items-center rounded mx-2 mx-lg-auto p-5 col-lg-8'>
            <h2 className="fs-3 mb-3">Présentation du projet "En stock!"</h2>
            <div className="separator"></div>
            <div className="mt-5">
                <div className="video-container d-flex justify-content-center" >
                    <iframe src="https://www.youtube.com/embed/LvktpFEiGdo?si=xplYkXG0Sdy2Zv63?autoplay=1&rel=0&loop=1&sharing=0&showinfo=0" 
                    title="Vidéo de présentation"
                    // frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                    style={{ border: "none" }}
                    ></iframe>
                </div>
            </div>   
        </div>
    )
}

export default EnStock;