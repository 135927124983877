import React from 'react';
import './About.css';

function About() {
    return (
        <div id='about' className='page d-flex flex-column align-items-center rounded mx-2 mx-lg-auto p-5 col-lg-8'>
            <h2 className="fs-3">Développeuse web fullstack</h2>
            <p className="fst-italic text-center">
                Spécialité de la formation : back-end, PHP/Symfony
            </p>
            <div className="separator"></div>
            <div className="align-items-stretch mt-5">
                <div className="row justify-content-center">
                    <div className="d-flex align-items-center justify-content-center mb-3 col-lg-3">
                        <img src="./img/profile-img.jpg" alt="photo Marion" className="img-fluid rounded-circle" width="130" />
                    </div>
                    <div className="d-flex align-items-center col-lg-4">
                        <ul className="list-unstyled">
                            <li><i className="bi bi-chevron-right"></i> <strong>Année de naissance :</strong> <span>1990</span>
                            </li>
                            <li><i className="bi bi-chevron-right"></i> <strong>Lieu de résidence :</strong> <span>Angers, France</span></li>
                        </ul>
                    </div>
                    <div className="d-flex align-items-center col-lg-4">
                        <ul className="list-unstyled">
                            <li><i className="bi bi-chevron-right"></i> <strong>Disponibilité : </strong> <span>dès à présent
                                </span></li>
                            <li><i className="bi bi-chevron-right"></i> <strong>Contact :</strong> 
                                <a href="https://www.linkedin.com/in/marion-dy-631545116" title="Lien vers profil Linkedin" target="_blank"><span>via Linkedin</span></a>
                            </li>
                        </ul>
                    </div>
                </div>
                <p className="about mt-4">Fraîchement sortie de formation mais déterminée à pratiquer le développement autant que possible afin de
                confirmer mes connaissances et d'en acquérir de nouvelles, je suis <b>à la recherche d'un emploi
                </b> en entreprise : CDD, CDI, à Angers ou en remote.</p>
                <p className="about">Je suis motivée et <b>prête à me former</b> dans les technologies/langages que je ne connais pas encore : n'hésitez
                donc pas à me contacter même si vous ne voyez pas certains de vos critères dans mon CV!
                </p>
            </div>

            <div className="separator2"></div>

            <div className="d-flex flex-column rounded mx-2 mx-lg-auto mt-4 col-lg-12" id="skills">
                <div className="text-center">
                    <h3 className="fs-3 mb-3 text-center">Compétences</h3>
                    {/* <p>Technologies utilisées :</p> */}
                </div>
                <div className="d-flex flex-column flex-lg-row gap-3 gap-lg-5 justify-content-center mt-3">
                    <div className="col-10 col-lg-3 mx-auto">
                        <div className="techno">HTML</div>
                        <div className="techno">CSS</div>
                        <div className="techno">PHP</div>
                        <div className="techno">SQL</div>
                        <div className="techno">JavaScript</div>
                        <div className="techno">TypeScript</div>
                    </div>
                    <div className="col-10 col-lg-3 mx-auto"> 
                        <div className="techno">Bootstrap</div>
                        <div className="techno">Symfony</div>
                        <div className="techno">Laravel</div>
                        <div className="techno">React</div>
                        <div className="techno">Node</div>
                        <div className="techno">Express</div>
                    </div>
                    <div className="col-10 col-lg-3 mx-auto">
                        <div className="techno">SGBDR</div>
                        <div className="techno">MariaDB</div>
                        <div className="techno">MongoDB</div>
                        <div className="techno">Prestashop</div>
                        <div className="techno">Wordpress</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About