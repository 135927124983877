import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Education from './pages/Education';
import Job from './pages/Job';
import Portfolio from './pages/Portfolio';
import EnStock from './pages/EnStock';
// import Footer from './components/Footer';


function App() {
  return (
    <div className="page-container">
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" exact Component={Home} />
          <Route path="/about" exact Component={About} />
          <Route path="/education" exact Component={Education} />
          <Route path="/job" exact Component={Job} />
          <Route path="/portfolio" exact Component={Portfolio} />
          <Route path="/presentation-enstock" exact Component={EnStock} />
        </Routes>
      </Router>
      <p id="footer">&#169; Copyright 2024 Marion Dy</p>
    </div>
  );
}

export default App;
