import React from 'react';
import { Link, useLocation } from "react-router-dom";
import './Navbar.css';

function Navbar() {

    const location = useLocation();
    const pathname = location.pathname;

    const handleThemes = (event) => {
        const themeColor = event.currentTarget.id;
        document.body.classList.remove("theme-1", "theme-2", "theme-3");
        document.body.classList.add(themeColor);
    };

    return (
        <>
            <nav className="navbar navbar-expand-lg flex-wrap" role="navigation">
                <div className="container-fluid mx-5 my-2">
                    <div className="d-flex flex-column flex-lg-row align-items-center justify-content-center">
                        <h1 className="navbar-brand fs-1 fw-700 mx-auto">Marion Dy</h1>
                        <div className="d-flex  ms-lg-5">
                            <a className="theme-switch" id="theme-1" onClick={handleThemes} title="Thème couleurs 1" role="button"><img src="./img/switch1.png" alt="Thème couleurs 1" width="50"/></a>
                            <a className="theme-switch" id="theme-2" onClick={handleThemes} title="Thème couleurs 2" role="button"><img src="./img/switch2.png" alt="Thème couleurs 2" width="50"/></a>
                            <a className="theme-switch" id="theme-3" onClick={handleThemes} title="Thème couleurs 3" role="button"><img src="./img/switch3.png" alt="Thème couleurs 3" width="50"/></a>
                        </div>
                    </div>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="#navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation" role="button">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0 gap-lg-5">
                            <li className="nav-item">
                                <Link to="/" className={`nav-link ${pathname === "/" ? "active" : ""}`}>
                                    Accueil
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/about" className={`nav-link ${pathname === "/about" ? "active" : ""}`}>
                                    A propos
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/education" className={`nav-link ${pathname === "/education" ? "active" : ""}`}>
                                    Formation
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/job" className={`nav-link ${pathname === "/job" ? "active" : ""}`}>
                                    Expérience
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/portfolio" className={`nav-link ${pathname === "/portfolio" ? "active" : ""}`}>
                                    Portfolio
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default Navbar;