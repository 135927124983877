import React from 'react';
import './Education.css';

function Education() {
    return (
        <div id='education' className='page d-flex flex-column align-items-center rounded mx-2 mx-lg-auto p-5 col-lg-8'>
            <h2 className="fs-3 mb-3">Formation</h2>
            <div className="separator2"></div>
                <div className="align-items-stretch mt-5 text-center">
                    <div className="education-item">
                        <h3 className="fs-5 p-0">Titre Pro de niveau 5 - Développeur Web et Web Mobile</h3>
                        <p className="mb-1"><em>Oct. 2023 - Avr. 2024 // Ecole O'Clock // Spécialisation PHP & Symfony</em></p>
                        <p>Formation intensive axée sur la pratique : HTML/CSS, PHP, Symfony, SQL, JS, Laravel, Github</p>
                    </div>
                    <div className="education-item mt-4">
                        <h3 className="fs-5">Diplôme d'Aptitude à l'Enseignement du Français Langue Étrangère</h3>
                        <p><em>2014-2015 // Alliance Française de Paris // Spécialisation en Technologies de l'Information et de la Communication</em></p>
                    </div>
                    <div className="education-item mt-4">
                        <h3 className="fs-5">Etudes de Lettres Modernes (M1)</h3>
                        <p><em>2008-2013 // Sorbonne Nouvelle // Mémoire : La crise du langage dans "La Pluie d'Eté" et "L'Eté 80" de M. Duras</em></p>
                    </div>
                </div>
            <h3 className="fs-4 mt-5 mb-3 text-center">Formations complémentaires</h3>
            <div className="separator"></div>
            <div className="mt-4 col-lg-8 text-center">
                <p>"Les data sciences de A à Z" (Udemy, 19.5h - mars 2021)</p>
                <p>"Enseigner avec Moodle", "Enseigner l'intercompréhension en langues romanes", "Nouvelles
                    méthodologies d'apprentissage du français"</p>
            </div>
        </div>
    )
}

export default Education