import React from 'react';
import './Job.css';

function Job() {
    return (
        <div id='job' className='page d-flex flex-column align-items-center rounded mx-2 mx-lg-auto p-5 col-lg-8'>
            <h2 className="fs-3 mb-3">Expérience professionnelle</h2>
            <div className="separator"></div>
            <div className="mt-5">
                <div className="job-item">
                    <h3 className="fs-5 p-0 text-center">Stagiaire développeuse web</h3>
                    <p className="mb-4 text-center"><em>Août 2024 - Oct. 2024 // Andragogy, Remote</em></p>
                    <ul className="list-unstyled">
                        <li className="pb-2 pb-lg-1"><i className="bi bi-check2-circle"></i>Développement d'outils internes en stack MERN : aides aux inscriptions et au suivi des étudiants et parcours </li>
                        <li className="pb-2 pb-lg-1"><i className="bi bi-check2-circle"></i>Rédaction de contenu pédagogique assistée par IA, relecture de cours</li>
                    </ul>
                </div>
                <div className="separator2 mt-5 mb-5"></div>
                <div className="job-item">
                    <h3 className="fs-5 p-0 text-center">Assistante de direction &amp; assistante chef de projet</h3>
                    <p className="mb-4 text-center"><em>Janv. 2018 - Sept. 2023 // Expo-Dispo Solutions Entreprises, Essonne</em></p>
                    <ul className="list-unstyled">
                        <li className="pb-2 pb-lg-1"><i className="bi bi-check2-circle"></i>Missions administratives diverses : archivage, suivi comptable et RH simple, recrutement, facturation,
                            dossiers administratifs (R&D, demandes de subvention, documents contractuels...)</li>
                        <li className="pb-2 pb-lg-1"><i className="bi bi-check2-circle"></i>Missions liées au e-commerce : suivi des commandes, du service client, suivi des flux et des
                            marketplaces, du CMS (Prestashop)</li>
                        <li><i className="bi bi-check2-circle"></i>Missions liées à l'approvisionnement produits et aux ventes BtoB : suivi des imports, conformité
                            produit et normes douanières, relations avec les usines et les clients (grande distribution), participation à l'organisation logistique et des processus internes</li>
                    </ul>
                </div>
                <div className="separator2 mt-5"></div>
                <div className="job-item">
                    <h3 className="fs-5 p-0 mt-5 text-center">Professeur de français langue étrangère</h3>
                    <p className="mb-4 text-center"><em>2014-2017 // France, Italie, République Tchèque</em></p>
                    <ul className="list-unstyled">
                        <li className="pb-2 pb-lg-1"><i className="bi bi-check2-circle"></i>Enseignement auprès de jeunes et d'adultes, en groupe ou cours individuels, préparation aux
                            examens, alphabétisation, enseignement à visée professionnelle ou récréative</li>
                        <li><i className="bi bi-check2-circle"></i>Observation, assistanat, recrutement, recherche de partenariats, organisation d'exposition
                            (Alliance française)</li>
                    </ul>
                </div>
            </div>    
        </div>
    )
}

export default Job